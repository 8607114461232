import React from "react";

export default function NotFound() {
  return (
      <div className="App">
        <h1>lilykat</h1>
        <i>is confused</i>
        <hr className="separator" />
        <small>
          <a href={"/"}>goto: home</a>
        </small>
        <p>
          whoops! you seem to be very lost - were you looking for me?
          <br />
          <a href={"https://www.ikea.com/us/en/search/?q=u%20need%20blahaj"}>
            <img width={200} src={"/images/blahaj.png"} alt={"a cute ikea shark plushie"} />
          </a>
        </p>
      </div>
  )
}