import React from "react";
import {BlogPosts} from "../data/blog-posts";

import {useParams} from 'react-router-dom';
import NotFound from "./NotFound";
import BlogPost from "./BlogPost";

export default function BlogPostView() {
  const { id } = useParams();

  const post = BlogPosts.find((post) => post.url === id);
  if (!post) {
    return <NotFound />
  }

  return (
      <div className={"App"}>
        <h1>lilykat</h1>
        <i>wrote a post</i>
        <hr className={"separator"} />
        <BlogPost post={post} full={true} />
      </div>
  )
}