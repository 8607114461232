import React from "react";
import Faq from "react-faq-component";

const faqData = [
    {
        title: "HRT Resources",
        rows: [
            {
                title: "Icky's Guide To MtF Medical Transition",
                content: <a href={"https://bigicky.gumroad.com/l/IwsvK"}>a very handy guide on the effects of HRT</a>
            },
            {
                title: "Erin's Informed Consent Map",
                content: <a href={"https://www.erininthemorning.com/p/erins-informed-consent-hrt-map-how"}>a US map of where to get HRT via informed consent</a>
            },
            {
                title: "Erin's Trans Safety Map",
                content: <a href={"https://www.erininthemorning.com/p/post-election-2024-anti-trans-risk"}>a map showing where in the US it's more and less safe to be trans</a>
            }
        ]
    },

    {
        title: "Oh no, but what if?",
        rows: [
            {
                title: "What if I'm too tall to be trans?",
                content:
                <>
                    <p>I'm 6' 5" and this has been the most difficult and painful point in considering transitioning. At one point, I came to the realization that I would already have transitioned if I was let's say, 5' 9" or anything short enough that I felt would allow me to pass.</p>

                    <p>This, along with male pattern baldness, was one of the two things that made me feel the most dysphoric. After all, there's nothing you can do to change your height, though some do claim to get up to 1-3 inches shorter on HRT.*</p>

                    <p>So late at night a few days ago, I did a little googling and found some Reddit threads where taller people were asking about whether they should even think about transitioning to female given that they felt that they would never be able to pass.</p>

                    <p>To my surprise, these threads were the most wholesome and encouraging that I'd read and genuinely surprised me. They totally changed my perspective on being tall and trans! I'd really suggest just reading through the posts below, but I've also summarized some of what's there and added my own thoughts and experience.</p>

                    <ul>
                        <li>
                            <a href="https://www.reddit.com/r/MtF/comments/14p65e0/tall_trans_women_how_do_you_cope/">Tall trans women: how do you cope?</a>
                        </li>
                        <li>
                            <a href="https://www.reddit.com/r/asktransgender/comments/ppkivz/too_tall_to_be_transgender/">too tall to be transgender?</a>
                        </li>
                        <li>
                            <a href="https://www.reddit.com/r/MtF/comments/11ok6hp/tall_girls_who_pass_whats_your_stories/">Tall girls who pass, what's your stories?
                            </a>
                        </li>
                        <li>
                            <a href="https://www.reddit.com/r/asktransgender/comments/xb8ae4/im_scared_im_too_tall_to_pass_mtf_transfem/">I'm scared I'm too tall to pass.</a>
                        </li>
                        <li>
                            <a href="https://www.reddit.com/r/asktransgender/comments/150gqkq/is_it_fine_to_be_trans_if_youre_over_6_feet_tall/">Is it fine to be trans if you're over 6 feet tall?
                            </a>
                        </li>
                    </ul>

                    <b>Height is not as important to passing as you might think.</b>
                    <p>
                        I was thinking that as soon as someone saw a person of my height that they'd immediately think, "oh, there's a biological male," but the reality is that most people don't really factor height into their perception of a person's gender.
                    </p>

                    <p>
                        Gender is something that most people take in at a glance without much conscious thought, and if you are presenting as a woman otherwise, people will be much more likely to see you as a tall woman.
                    </p>

                    <p>
                        Further, there are MANY women who are taller then 6' and quite a few who are as tall as or even taller than me. Just to show this very quickly, check out this <a href="https://www.wnba.com/players?team=all&position=all&show-historic-players=false">list of Women's NBA players</a> or <a href="https://www.thedelite.com/tallest-actresses-hollywood/7/">list of tallest actresses</a>. There's quite a few who are my height and one is 6' 9"!
                    </p>

                    <p>Now it is true that being very tall might cause people to notice you which could increase the risk of being clocked, but being tall does not mean that you cannot pass! These feelings may just come from our own biases and perspective, since obviously we know that we are trans and are often our own harshest critics.</p>

                    <b>Many people find tall women very attractive.</b>

                    <p>I obviously don't have stats on this, but there seem to be many people, both men and women and myself included, who find and know others who find tall women to be extremely attractive! When I was in university, there was a woman in a few of my classes who I think was 6' 4". She played basketball and I thought she was beautiful. I would have asked her out too, but at the time, I was a conservative Christian and she was agnostic, and such relationships were strictly off limits.</p>

                    <p>It's also the case that most models are very tall, often as tall as 6' or more. While I'm sure there will be some (woefully insecure) guys who will be intimidated by taller ladies, I can't see this being a significant issue and those seem like the kind of people that you may want to rule out anyway. Women, as a general rule, aren't bothered by having a taller partner, so in either case, height shouldn't be a major issue.</p>

                    <p>It was also noted that there are cis women who feel dysphoria about being too tall and how this limits their possible partners, but most tall cis women do not experience this, so it doesn't seem to be an inherent aspect of being tall and trans.</p>

                    <p>One other benefit to being very tall and trans is that you are less likely to face physical intimidation. One Reddit user noted how much fun it was to physically intimidate shorter men. 😁 </p>

                    <small><i>* - the official consensus is that you cannot get shorter by this amount, however the anecdotes abound so I'm not sure what to think. My best guess is that changes to ligaments, hip tilt, measurement error, and shrinking with aging are contributing to these reports.</i></small>
                </>
            },
            {
                title: "What if I'm losing my hair?",
                content: <>
                    <p>
                        Ooh - this one is easy now that I've had some time. I was losing my hair, lots of receding at the front and temples, and a nice little bald spot forming at the top! It was horrible and one of my main reasons for not wanting to transition - I couldn't be a bald woman!
                    </p>
                    <p>
                        Thankfully, the solutions are fairly straight-forward:
                    </p>
                    <p>
                        <b>1. Get comfortable with wigs</b>
                        <br />
                        This is really the best option. Wigs are not terribly expensive, and some kinds of wigs (i.e. ones with bangs) are super easy to maintain and wear. They're also super cute and gives you a TON of flexibility in your look. At first, this might seem daunting or off-putting, but it's really fine - accept that. Reality isn't perfect and if you can become comfortable with wigs, that will be a huge win for your outlook moving forward.
                    </p>
                    <p>
                        <b>2. Get on the meds</b>
                        <br />
                        The other thing that you can do is take <i>finasteride</i> and <i>minoxidil.</i>. These are the standard hair loss prevention and regrowth meds and they work really well with minimal to no side effects for most people. Your mileage may vary, but if you want any chance of regrowth you need to be on them. Do note that even if you're on HRT, you should be taking at least finasteride, and also note that if you hair loss regimen contains <i>biotin</i> that this may affect your HRT lab results for estrogen and testosterone by artificially raising them. Insurance may also cover finasteride if you have a diagnosis of gender dysphoria.
                    </p>
                    <p>
                        <b>3. HRT</b>
                        <br />
                        For some people, HRT alone can cause dramatic hair regrowth and prevention of future loss. The official guidelines will all say that regrowth is impossible, but <i>they are wrong</i>. Much of the information that is accepted in the mainstream literature on trans health is inaccurate or outdated and many, if not most, trans women who I have spoken to have reported some amount of hair regrowth. It is, however, not guaranteed.
                    </p>
                    <p>
                        <b>4. Hair transplants</b>
                        <br />
                        I don't know as much about this because I see this as a last resort, but I do know that insurance may cover hair transplants if you have a diagnosis of gender dysphoria. I know lots of people who have done this and it has good results, but you definitely want to have been on HRT and fin/min for several years prior so that you can see where that gets you. I have seen many people who get significant regrowth just from HRT.
                    </p>
                </>
            },
            {
                title: "What if I'm too old to transition?",
                content: <>
                    <p>
                        My biggest regret in life is that I didn't transition earlier. It's not like I could have - I didn't know that I was trans until I was 29, but I would now give anything to go back in time to tell my younger self that I was really a girl. I could have saved myself from so much trauma--and harm from testosterone.
                    </p>
                    <p>
                        Now that I'm 30 and transitioning, I still feel like I'm too old. It's sometimes sad. I've missed out on so many things, and there's no way to make up for that. That doesn't mean that I don't have joy and gender euphoria, but there's still a weight.
                    </p>
                    <p>
                        And every year you wait, that burden only gets heavier. And you'd be surprised at what you do get in transitioning! When you transition, you will get to experience the puberty that you always wanted, and many of the same things that cis girls get to. If you choose that, it's going to be wonderful, and in some ways I think it's even more beautiful because you get to experience with clarity the process of becoming who you knew you always were. Not many people get to experience such a transformation, and at times I'm overcome by gratitude and awe that I get to experience this at all.
                    </p>
                    <p>
                        Being yourself is worth it no matter how old you are. 🫂
                    </p>
                </>
            }
        ]
    },

    {
        title: "Content Creators",
        rows: [
            {
                title: 'Erin Reed',
                content: <>
                    <p>Erin Reed is your go-to journalist for trans news. She's just hands down the best if you want to stay informed. Support her work if you can too. It's great to stay informed, but also try to take some breaks too as what's in the news at thep time of this writing is pretty bleak and not great for one's mental health.</p>

                    <p>
                        Check her site out her: <a href={"https://www.erininthemorning.com/"}>Erin in the Morning</a>
                    </p>
                </>
            },
            {
                title: "ContraPoints",
                content: <>
                    <p>
                        ContraPoints (Natalie Wynn) makes incredibly insightful and entertaining video essays on political and philosophical topics, with a focus on gender and trans issues. I've just recently finished binging all of her content!
                    </p>
                    <p>
                        She has more content on her Patreon, but I haven't checked that out yet.
                    </p>
                    <p>
                        <b>Links:</b>
                        <ul>
                            <li>
                                <a href="https://www.youtube.com/@ContraPoints">ContraPoint's Youtube</a>
                            </li>
                        </ul>
                    </p>
                </>
            },
            {
                title: "Icky",
                content: <>
                    <p>
                        Icky (Ashley) runs a YouTube channel giving advice primarily targeted to people who are considering transitioning from male to female, or who are early on in their transition.
                    </p>
                    <p>
                        She also focuses a lot on fashion and has created a fairly comprehensive guide to medically transitioning through HRT.
                    </p>
                    <p>
                        <b>Links:</b>
                        <ul>
                            <li>
                                <a href="https://www.youtube.com/@bigicky">Icky's Youtube</a>
                            </li>
                            <li>
                                <a href="https://bigicky.gumroad.com/l/IwsvK">ICKY’S GUIDE TO MtF MEDICAL TRANSITION (free PDF download)</a>
                            </li>
                        </ul>
                    </p>
                </>
            },
            {
                title: "Gwebulls",
                content: <>
                    <p>
                        Gwebulls is a TikTok content creator who I really like and gives really good advice for simple and practical ways to transition and present more femininely. She has a really cute series called "TransGirl Tips: Speedfire Edition" that you'll really love.
                    </p>
                    <p>
                        <b>Links:</b>
                        <ul>
                            <li>
                                <a href="https://www.tiktok.com/@gwebulls">Gwebull's TikTok</a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/gwebulls/">Gwebull's Instagram</a>
                            </li>
                        </ul>
                    </p>
                </>
            },
            {
                title: "Ashley Adamson",
                content: <>
                    <p>
                        Ashley Adamson has a YouTube channel where she talks about MtF topics generally, but has a bit of an emphasis on spirituality which I find helpful. She also talks openly about sexuality and psychedelic use which is also quite interesting.

                        She also has a Discord community (invite link on her YouTube channel) which offers a lot of support and advice. (I'm in there and have gotten a lot out of it!)
                    </p>
                    <p>
                        <b>Links:</b>
                        <ul>
                            <li>
                                <a href="https://www.youtube.com/@AshleyxAdamson">Ashley's Youtube</a>
                            </li>
                        </ul>
                    </p>
                </>
            },
            {
                title: "Lily Alexandre",
                content: <>
                    <p>
                        Lily's YouTube channel features a host of very in-depth and insightful political and philosophical video essays on gender.
                    </p>
                    <p>
                        <b>Links:</b>
                        <ul>
                            <li>
                                <a href="https://www.youtube.com/@lily_lxndr">Lily's YouTube</a>
                            </li>
                        </ul>
                    </p>
                </>
            },
            {
                title: "Sydney Viera",
                content: <>
                    <p>
                        Sydney's TikTok offers a lot of advice on transitioning, makeup tutorials, trans humor, and just a lot of her personal experience which I've found really helpful.
                    </p>
                    <p>
                        <b>Links:</b>
                        <ul>
                            <li>
                                <a href="https://www.tiktok.com/@sydviera">Sydney's TikTok</a>
                            </li>
                            <li>
                                <a href={"https://www.instagram.com/sydviera/"}>Sydney's Instagram</a>
                            </li>
                        </ul>
                    </p>
                </>
            },
            {
                title: "Serenity Devine",
                content: <>
                    <p>
                        This TikTok is one I found before I was really even thinking about gender identity or transitioning in any serious way. It's pretty much just trans humor, but I found it really funny and it definitely made me start thinking about things.
                    </p>
                    <p>
                        <b>Links:</b>
                        <ul>
                            <li>
                                <a href="https://www.tiktok.com/@serenity_devine">Serenity's TikTok</a>
                            </li>
                        </ul>
                    </p>
                </>
            }
        ]
    }
]

export default function TransFem() {
    return (
        <div className="App">
            <h1>lilykat</h1>
            <i>...is trans?!</i>
            <hr className="separator" />
            <p>As I've been exploring my gender identity (<s>currently genderfluid, but slowly inching toward trans</s> <b>edit: totally trans</b>) I've had many questions and concerns, and much existential dread. 😭 However, I've also stumbled across many wonderfully helpful resource, and I've decided to begin compiling them here so that anyone else who's on a similar journey can benefit from them too. 🥰</p>
            {faqData.map((data, i) => (
                <Faq data={data} key={i} styles={{
                    rowContentPaddingBottom: 20
                }} />
            ))}
        </div>
    )
}