export type BlogPostType = {
  title: string
  date: string
  content: string
  description?: string
  author: string
  url: string
};

export const BlogPosts: BlogPostType[] = [
  {
    title: 'Pronouns as Power',
    date: 'Friday, January 10th 2025',
    content: 'stuff about pronouns and being transphopibc in the past!',
    description: 'stuff about pronouns and being transphopibc in the past!',
    author: 'lilykat',
    url: 'pronouns-as-power'
  }
];