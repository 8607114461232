import React from "react";
import Blog from "./Blog";

export default function Home() {
    return (
        <div className="App">
            <h1>lilykat</h1>
            <i>has a website</i>
            <hr className="separator" />
          <p>
            hi!! I'm kat and I study dreams and write music. I've got a little discord server that you can join if you'd like a welcoming community of people to collaborate with on music projects! I also have some resources here for transfem people and write a bit about my own dreams and other things related to gender.
          </p>
          {false && (<>
            <hr className="separator" />
            <Blog />
          </>)}

        </div>
    )
}