import React from "react";
import nl2br from "react-nl2br";
import {BlogPostType} from "../data/blog-posts";


export default function BlogPost({ post, full }: { post: BlogPostType, full: boolean }) {
    return (
        <div>
          <h3>{post.title}</h3>
          <i>{post.author} | {post.date}</i>
          <hr className={"separator"} />
          {full ? nl2br(post.content) : nl2br(post.description)}
          {!full && (
              <p>
                <a href={"/post/" + post.url}>Read more...</a>
              </p>
          )}
        </div>
    )
}