import React from "react";
import nl2br from "react-nl2br";

const preTransitionDreams = [
  {
    title: "Fairytale",
    date: "2000-01-01 [age 5-7]",
    content: "I found myself in an dark, fantasy world in which I was both a narrator of the story and a character within it. I may have been female and a witch, or that may have just been the main character. It was night time, and while I don't remember much, I do remember that the story took a long time to complete and involved a witch having to journey to some tree to find a potion.",
    notes: "This was a very early dream and the first I can remember which had a narrative structure. I'm not sure of the exact date."
  },
  {
    title: "Zombie Apocalypse",
    date: "2018-01-28 [age 23]",
    content: "I was in a valley surrounded by woods with two male friends and possible some other friends and family. There were some questionable characters there, and one friend didn't want anyone to know who he was. I was joking around about how he was Robin Hood, and as it turns out, that was his secret identity in the dream. He told me to stop calling him that. \n" +
        "...\n" +
        "We were at our university in a restaurant which may have been the cafeteria. We were preparing for a war, possibly against zombies, but I'm not sure they had entered into the dream yet. Night fell completely, and I left the building we were in. As I walked around the campus at night, I noticed that there were tanks rolling silently through the grounds, and I was petrified in fear that they would see me. At this point in the dream, and at others, I was female. I'm not sure what this means exactly or how I knew it to be true, but it was. It's interesting looking back on the feeling, as it's definitely one I've had in my waking life. (Explore this idea...)\n" +
        "\n" +
        "I found a spot under some bushes slightly up a hill behind a large building. But as I tried to hide myself under the bushes and under a blanket that I had with me, I saw that there was a bright green light emanating from the building which would allow the people in the tanks to see me. I fled to a building and this is when the dream turned into the zombie apocalypse. In the house, there were my friends, but also zombies who were attacking and biting people. In all of the confusion, I saw that one of my friends (or maybe just some random person), and then I myself was bitten on the shoulder. (This is where everyone was bitten, if I remember correctly.) When I was bitten, I'm pretty sure that I respawned as a different dream character and that I was now male. I'm not 100% sure about this. \n" +
        "\n" +
        "As I left the house, there was a man in the dream who was trying to escape the campus. He was someone of great authority and also bad. He was at the top of some parking-garage like structure and he was getting on a white moped. He was about to drive off of the edge to his death, but he jumped off and let the moped careen down. As it fell, jumped into a vehicle and made his escape. His plan was to use the moped, which turned into a hummer as it fell to distract the tanks, soldiers and zombies below. The hummer fell right next to a tank and some soldiers. As the soldiers tried to get out of the way, they were taken down by zombies. \n" +
        "...\n" +
        "I was now a soldier and walking down the road with an entire group of soldiers. It was then that I found out that everyone was slowly getting sick and lethargic and transitioning while still alive into zombies. One of my compatriots did this while I was not paying attention, took a chunk out of my soldier and then I switched to a new soldier. My side kick told me that he was starting to get tired, and as we ran down the road, I could tell that I was getting tired as well. We both knew that we were turning and told one another to shoot the other when we turned. As we rounded a corner we saw a large green dumpster and next to it a girl. She had turned, and while the soldiers were rescuing a boy on top of the dumpster who had not yet turned, I shot the girl in the head. But she didn't die, and we realized that child zombies could not be killed.",
    notes: ""
  },
  {
    title: "Transformations",
    date: "2018-11-01",
    content: "It was at night and I was with my parents and brother, and some other people in a house bordering a lake or pond. At some point in the dream, I transformed into a duck and was in the water, and this became worrying to me. I'm not sure anyone else knew. I think I became female at some point in the dream, as did my brother. We went outside and of the house and there was a swimming pool. My brother/sister also transformed into something—or I did but was my sister (it's really confusing)—and I was watching. I think I put my head under the water and made creepy noises either to pretend to be the creepy thing that my sister had transformed into or because I was the creepy thing.",
    notes: ""
  },
  {
    title: "Abusive Father",
    date: "2019-10-09 [age 24]",
    content: "I was a girl about the age of 13 and my younger brother was several years younger. Our father was an evil psychopath who was physically abusing my brother by beating him till his back was covered with sores and open wounds which he would enjoy looking at. I saw him doing this in the dream. He then turned to me, and sizing me up, noted that I would soon be going through puberty. His intentions were clear. I knew that we had to escape so one night I took my brother and we ran out of the house and made our way to a train station. Our father knew that we had escaped however and followed us. We got to the train station before he did and by the time he arrived, I had already gotten my brother onto a train and he was safely away. I only hoped that my father wouldn't be able to do anything with all of the other people around. I had the feeling that I would be safe now. He came up to me and promised that he would be better and that we had to come home, but I wasn't falling for it. I'm not sure if he was legitimately sorry or not—I kind of think that he was, but it didn't matter at this point. I managed to get past him and jump into the back car of a train. I say into, because it was a child-sized train like the ones that small kids ride around in at amusement parks. We were away, but I was worried for my brother. We were heading to an airport but I wasn't sure how I'd meet up with him or how we'd begin a new life away from our father.",
    notes: ""
  },
  {
    title: "Roleplaying as Female",
    date: "2020-01-28 [age 25]",
    content: "I was with a male friend and we were at some mixture of a hotel and a brothel. We asked for rooms, possibly to sleep with prostitutes, but they were out of one or the other so he and I ended up staying in the same room by ourselves. We then made the decision that since there was no one else around, that one of us would have to pretend to be the girl so that the other could either have sex with them or just sleep lying next to/against them. I think the latter is the case. In any case, I had either chosen or was designated to be the girl, lay down in the same bed as my friend, and allowed him to hug me from behind. \n" +
      "\n" +
      "At some point, I think that we realized that this was just incredibly awkward for both of us and that it was never going to work so we discontinued.\n" +
      "\n" +
      "In the dream, I sort of took on the characteristics of being female, which is an idea that I had been playing around with--trying to imagine what it must be like to be female. I can sort of imagine this in some ways but obviously this pales in comparison with the real thing.",
    notes: "",
  },
  {
    title: "Spider Fall",
    date: "2020-04-26",
    content: "The bad guys were after me as I was driving my partially pedalled car down the road. The scary part is that they were in a train. Somehow they had jumped the train off of the tracks and were driving it down the road. My car wasn't very fast and they were gaining on me, but I figured that I would have a better turning radius than a train, so I darted down a side road, jumped off the side off of a bridge down to a smaller road below and attempted to hide from them. \n" +
      "\n" +
      "I slowly and quietly made my way to my base where I had a secret device which the bad guys were after. I'm not sure what it did, but it was electronic and small enough to be moved by a single person. We were by the side of a cliff at the edge of my complex where my device was being used to create a spider web down below the cliff and extending out several dozen feet. It had been daytime before, but now it was night. There was a woman was who had been drugged or was otherwise unstable, and began to fall. As she fell, she may have turned into a spider. I also felt at times as if the woman was me. I let the woman fall into the net and escape.\n" +
      "\n" +
      "I left, and later two friends were talking to me and wanted to see my device. I had to tell them that they couldn't because of safety concerns. I was worried that they'd fall into the spider web as well which seemed to attract people toward it. I then copied my secret device code into C:/Open Folder, encrypted it, and deleted the old copy.",
    notes: ""
  },
  {
    title: "Aurora's Favorite Geography",
    date: "2020-05-07"  ,
    content: "Aurora [artist] was discussing her favorite climate or geography and it came down to either lush forests in the US, or Norway's snowy mountains. She went with the US and took a helicopter ride there. When she touched down, it wasn't in the lush forests but in an arid desert canyon with a cold stream running down a steep hill. Many tourists were in the area and wanted to meet Aurora, but she went down by herself to a small rounded area down by the stream where the ground was covered in river stones and fallen trees. She rolled over the trees playfully. There wasn't much of a distinction between myself and her at the end of the dream.",
    notes: ""
  },
  {
    title: "Medical History",
    date: "2020-05-07",
    content: "I was at a doctor's appointment in an unfamiliar office. I had just returned to the waiting room where I sat down and waited for the woman at the check-out area to call me so that I could leave. After sitting for a while and not being called, I began to worry that I was sitting in the wrong place and had to go approach the check-out window myself. I did this and as I approached, I found that I was not wearing a shirt but instead had a large blanket wrapped around my torso and mouth. When I came to the window, the receptionist mistook me for a lesbian woman who was one of the patients there. She asked me something about my medical history and I became curious. I asked her to clarify her question and provide more details and soon found out that I could discover much about this person. I was very curious, but knew that this was wrong. I overcame my curiosity and made clear who I was. The receptionist asked why I was wearing the blanket around my mouth to which I responded by telling her that I didn't have a mask. She handed me a large, sturdy, grey and pink N95-style mask which I examined. I am not sure if I put it on.",
    notes: ""
  },
  {
    title: "Camping Trip",
    date: "2020-09-21",
    content: "I'm a mother with my family on a camping trip. My husband is present and I have at least two children. One is a boy of 6 and the other is a girl who is slightly older. Both have blonde hair. We are at a river flowing from left to right and my son is swimming. Somehow I've already seen into the future and know that he is going to be swept downstream later. We go to bed that night and I am woken up to find that my son is drowning. I go and rescue him. This happens a second time and I am still able to save him. The third time, however, he is swept downstream. I become the father and watch as he is carried off. My wife has jumped into the river after him and I rush down the bank to try to catch up. I jump into the river and swim out after them, but the flash flood is too powerful. I am almost swept away but am able to catch onto a tree that is growing in the water. The water rises in waves and I am tossed up and down barely able to hang onto the tree. I am thrust under water for longer periods of time and am not sure if I will be able to hold my breath. I have to let go of the tree and am propelled up into the air. I fall back into the water and the process repeats. When back up in the air, also possibly for the third time, I see a platform made of orange-yellow tiles in a 3x3 grid pattern. They appear brighter at the edges and almost virtual. A man stands on them and is either removing the tiles below me so that I fall through or is not helping to place them. Eventually I or him am able to place a tile down so that my fall is caught and I am out of the river. I wonder if this man is Jesus.",
    notes: ""
  },
  {
    title: "Arya Stark",
    date: "2020-10-10",
    content: "I am Arya Stark at a desert castle preparing for an attack by unsullied soldiers. A spear is thrust between my legs, but only grazes me, and we are able to win because they weren't expecting females. Slaves are still being taken, and women are being mutilated in order that they won't be desired by men. One woman is tied down and naked and there are repeated attempts to attack her from another woman. She is able to protect herself, and I try to stop them.",
    notes: ""
  },
  {
    title: "",
    date: "2020-12-05 [age 26]",
    content: "I was female and at my mother's house. It was mostly dark in the house. My sister was with me. Some great psychologist was visiting our mother. He was a large man, middle aged and balding with stubbly grey hair and beard.\n" +
      "\n" +
      "He was speaking with our mom. He was to my left and our mom was at my right. As part if the therapy that our mom was doing, she had a part of her self she called version B which was more attractive and confident. She told him that her B version would be willing to have sex with him. He paused thinking because this was quite the serious proposal. He told her that he would but only with her real self, not version B. Our mom hesitated but eventually agreed.",
    notes: ""
  },
  {
    title: "Snake and Mouse",
    date: "2021-01-11",
    content: "In a mythical desert there was a large venomous snake coiled up waiting. At night, a small mouse approached the snake to get advice about what to do in the world below. The snake answered in a voice which was cold and condescending and his tongue flicked in a and out brushing up against the mouse. The mouse grew angry with the snake because of an old grudge. Snakes had long been preying on his kind. The mouse got right up to the mouth of the snake, but then left to do the snake's bidding.\n" +
      "\n" +
      "The snake felt lonely and went to a tree and curled up. Then I, a female entity, approached the serpent and told it that it too needed to find love. I didn't think that it would want to admit this. I reached out my hand and stroked it to comfort it. It accepted it and felt oddly furry.\n" +
      "\n" +
      "I could sense that the snake was beginning to feel some sort of emotions and I wanted it to pursue them and not reject them in cold, snakelike fashion.",
    notes: ""
  },
  {
    title: "me & my mom",
    date: "2021-02-26",
    content: "A quick nap. I was in my apartment complex's gym with my mom. We were ready to leave and she said that she was going to write an X on the sign out sheet on the wall. I realized I was dreaming and that my perspective in the dream was off. I was my mom and I spoke to her/myself in an attempt to stay in the dream.",
    notes: ""
  },
  {
    title: "Wife of Terrence McKenna",
    date: "2021-03-28",
    content: "I am the wife or girlfriend of Terrence McKenna and am staying at his house. The house is very fancy, with wooden floors and multiple stories. I'm upstairs in a room with a Pine snake. It's being used for some sort of ritual. I want to hold it and want to take a picture with my camera. It's hard to get the picture because the snake is biting me and trying to get away. It's quite strong, so I ask a woman nearby to take the picture for me. She gets a few pictures. The snake's fangs weren't penetrating very far, but when it opens its mouth, I see that it's fangs are quite long. I keep holding it but the snake defecates on me creating a mess. I lay the snake in the left side of a deep sink and go to wash up. I return to the sink and try to clean off my hands in the cold water but it isn't doing a very good job. I ask the woman if she thinks the snake will ever get used to me and she thinks that it will. I see the snake running around in a little groove of water which it can't get out of. The snake has shrunk significantly and when I finish washing my hands it is only 3 inches long. \n" +
      "\n" +
      "I got to bed and wake up to find that Terrence McKenna is downstairs and getting ready to explain his scientific ideas. I want to learn from him. I go downstairs and see that Jonathan Ott is standing near a piano. He asks if the car has been warmed and prepared for him which it has. I get the feeling that I'm not that important here. I think we head out to the cars.",
    notes: ""
  },
  {
    title: "Escape",
    date: "2021-04-24",
    content: "I was a teenage girl and I was leading a group of kids who were trying to escape from a facility that had trapped us. We had gotten out but we needed to return because the train which would take us away was inside. Sneaking back in was dangerous as they would be expecting it and it would have to be done in broad daylight. I led them into the facility by digging under the chain link fence and worming our way through. We climbed down a hill on the other side of the train tracks. They expected us to get directly on the train but I decided that we would wait until the train began to move, then jump onto the empty flatbed car at the end. We waited till it began moving and I rushed up to jump on just in time. I was away and no one had noticed or at least hadn't been able to do anything about it. \n" +
      "\n" +
      "I lay on my back as the train picked up speed. I worried that the flat bed car would get pretty hot from the friction of the wheels below. I could feel my back growing warm and thought about what it would be like jumping off of a moving train. \n" +
      "\n" +
      "I either went inside of a train car or got off and made my way to a house or hotel room. My mother and father were there but they were evil and wanted to take me back to the facility. My dad attacked me and cut my face so that they had to stitch it. Then my mom was wanting to hurt me so I fought her pinning her to the ground by holding her throat. I looked for something to kill her with but didn't see anything. I took hold of a plate and tried to smash it against the table top but it was sturdy. She pleaded with me not to kill her but I knew she would have killed me. It was difficult but I decided to kill her. I broke the plate finally and using a shard I slashed at her neck. I missed the jugular and only cut her face. My next attacks landed and she began to bleed out. I then realized that I had to clean up the mess as I was a fugitive murderer now and didn't want to be caught. I went to find some rags to clean up but when I got back there was no blood. The rags were stained green though which meant that they had been used to clean up the blood but had been stained oddly by it.",
    notes: ""
  },
  {
    title: "me & my shadow",
    date: "2021-05-19",
    content: "My shadow was a living being and I was a woman trying to escape from it. It wanted to possess me and was chasing me through dim hallways. It grabbed me and tried to film the event but I escaped or was let go. I swapped out the film in the camera and gave it back keeping the real film.",
    notes: ""
  },
  {
    title: "AIDS",
    date: "2021-07-02",
    content: "I was a female nurse working in a hospital and I had contracted AIDS because I had forgotten to wash my black high heeled shoes which had carried the virus.",
    notes: ""
  },
  {
    title: "Superhuman",
    date: "2021-08-07",
    content: "I was part of a group of superhumans and we were trying to break into a secured facility. I was a woman. We climbed up the building and got into an argument with our female leader about what to do next. Above us there was a small opening which we had to get through but I wasn't sure that we could. I climbed up and by sliding my body through and twisting sideways I was able to slip through. Perspective was off because we had to drop through a series of these openings but I'm not sure we were going down. \n" +
      "\n" +
      "I got to a door to the facility and we decided to break in. I opened a door but a guard who was there saw me and shot me. I wasn't worried because we were too powerful for bullets to stop us. \n" +
      "\n" +
      "The guard tried to kill be ny exploding a tank of gas next to him, but it only killed him.",
    notes: ""
  },
  {
    title: "Seduction",
    date: "2021-12-12 [age 27]",
    content: "I was in a building in which I was being kept as a prisoner. There were others and maybe a plan to escape. We were out of our cells and some got away but the enemy came and we were all terrified. He was stronger than all of us. I went back to my cage which was a half circle impression in the wall with a first set of bars then a heavy, dark steel divider that would slide down to keep us secure. Behind that was a bed which I tried to jump up on before he shut it so that I wouldn't be crushed.\n" +
      "\n" +
      "Later we might have fought but the details are not clear. \n" +
      "\n" +
      "He came to me and I was scared that he was going to kill me. As he approached I said that I was afraid to die. He held a white bone knife in his hand. He didn't kill me then but came back and tried to step on my chest to kill me. He did it again and came back. I began sobbing and begging for forgiveness and he comforted me, coming to me and allowing me to hug him and rest my head on his lap. My sobbing became to be just as much for him as for myself as I slowly loosened my hug and using my right hand found the dagger that I had with me. This was my way to lure him to kill him. I plunged the dagger into his back. He got up possibly unharmed and not at all angry about what I had done. We sort of fell in love and started having sex. I was sort of female but it was all unclear. I was partially awake and didn't want anyone to see us so we moved to a more secluded corner and continued...",
    notes: ""
  },
  {
    title: "A Woman with My Name",
    date: "2022-02-14",
    content: "I was trying to descend down levels in a Fortnite-like game but was killed trying. I tried again and this time was in an airport. I went down with my mom to the place I had tried to go down before. I told my mom that I had to do this as I walked down the stairs. Immediately I was faced with a challenge as a woman to my left was in my way and I had to get past her. I said sorry to her but I wasn't sure if I needed to. The interaction was confused and I maybe wasn't sure if she had said anything. \n" +
      "\n" +
      "I walked into the crowd when I heard someone call my name. I turn and see that it's a woman, and I start to answer but see that she is looking toward a couple next to me. It is a woman about my age with blond hair and a guy with a dark beard. We realize that I and the woman are both named [deadname]. We speak for a moment and laugh at the confusion. I'm attracted to her but she is with this guy so I simply leave. I think we wave to one another. \n" +
      "\n" +
      "The woman is upstairs when I find out that someone else is trying to hit on her. Someone, I think one of my friends, is with me and I figure out with him that she can't be together with the guy she was with because he isn't at all bothered by the guy hitting on her. My friend tells me that I have to go after her. \n" +
      "\n" +
      "There is an old man, balding with wispy grey hair. He's overweight and possibly shirtless. For some reason he leads me up the staircase after the woman. He turns right but I was sure the woman turned left. He tries to lead me to the right and I start to go with him but then decide to go the direction I think she went. \n" +
      "\n" +
      "I catch up with the woman and she is excited to see me. We speak and are getting along well. I tell her about the cat named [deadname]-ita and explain that the name means \"little [deadname]\". \n" +
      "\n" +
      "We embrace and it's implied that we are now dating.",
    notes: "There was actually a cat I met on an Ayahuasca retreat which really liked me but was skittish with most of the other guests. We didn't know what it was called, so a woman there named it after me, but made it a female name with the -ita suffix."
  },
  {
    title: "Vienna & The Resistance",
    date: "2022-05-19",
    content: "I was in a city like Vienna living in a nice house. There was a war or famine and everyone was low on food. The father of the family that I was a part of was sent out to try to find food. He didn't have any clean clothes to wear, so he put on an old red flannel shirt and went out. I opened a door in the house and found myself at a spiritual retreat center. There were a host of people sitting on the floor each with a teacher who was giving them instruction. I knew some of them to be the participants from Arkana. I realized that some of them were being taken advantage of or given bogus teachings, but it wasn't my place to interfere. I approached one of them where he was being given instructions from a male teacher. The teacher told him that he would have to sit for a full day at the base of a pole with his hands tied behind him and around the pole. He was extremely skeptical of this and kept asking questions to confirm that he was understanding correctly. The guide told him that he would be given a guided meditation using a method called \"gim\" with a hard G. He would have to listen to it using headphones as there would be no actual person to lead the session. He wasn't happy about this and said, \"Are you telling me that I'm going to be stuck sitting all day tied to a pole and that you're going to jam headphones into my ears?\" He still went through with it and I left him tied to the pole. At some point, I made my way into a restroom and found very ornate hexagonal stalls with wooden doors. They were at different heights stacked next to one another. I got into one of the lower ones but was worried that someone in a higher one would be able to see me.\n" +
        "\n" +
        "I was now the mother of the family and I'm driving the car so that my husband can get food for the family. Some article of clothing gets lodged in the car making it difficult to drive. My husband gets angry at me and says he is going to divorce me even though it wasn't my fault. I almost lose control of the car and drive across at least six lanes of oncoming traffic almost hitting several vehicles. All of the cars look like they are from the 1920s and 1930s. I make it to the other side of the road and end up driving into someone's yard.\n" +
        "\n" +
        "I find myself in a flying car which is steam and air powered. I take off and have some trouble staying airborne. I fly out over the ocean, over a beach of large rocks, and am able to get fully into the air. I fly up to a base floating in the sky where I am part of the resistance movement. Inside of the base, jets of air and steam guide my car to the right spot and jets from below give me a safe and smooth landing. I get out of the car and find two people who in the dream are my brother and sister. They are skeptical of my mode of transportation and think it is unsafe.",
    notes: ""
  },
  {
    title: "Vin",
    date: "2022-05-22",
    content: "I was listening to the 3rd Mistborn book by Brandon Sanderson. I remember there being different voices for the characters and that there was something odd about them. I was in and out of the story, and may have been Vin at some point.",
    notes: ""
  },
  {
    title: "Kuvira",
    date: "2022-05-25",
    content: "I'm fighting in a battle. I'm Kuvira [from Legend of Korra] and I'm inside of the giant mech from the final season. I'm going to control it and use it's weapon to destroy the enemy ground troops. Before I do this, one of the enemy has infiltrated a structure where they are speaking to someone, maybe receiving instruction or advice. The building is large and circular with stone floor and stone pillars spaced around the edges with only a few feet of space between. I have a sniper rifle and have a shot lined up to take out the enemy. Someone, I think my brother, is next to me and keeps bumping my rifle so that I will lose focus telling me that I am not supposed to shoot the person. I am annoyed and tell him that I am not going to shoot but need to keep them in focus so that I can shoot if I am told to. I pull slightly back on the trigger to be prepared and this caused my brother to bump the gun again. \n" +
        "\n" +
        "I'm now ascending on a moving platform to the top of the mech where the control center is. At this point, I think that I don't want to keep fighting and want to make sure that I do as little damage as possible. The mech is sentient and will know if I am too obvious about it. I fire a few times, mostly aiming below the areas that would do the most damage. The mech is also being attacked by the enemy and isn't working well. I am thrown about and have to grab onto metal arms and satellite disks to prevent myself from falling down.\n" +
        "\n" +
        "I'm outside of the main conflict looking into crowds of people. They are engulfed in dust and flames and are screaming for the war to stop. I want to join in but can't bring myself to raise my voice. I walk among the people and find my way to the center where I may tell people to stop fighting. The war is over and my side has lost. I give myself up and a group of five or six of us are kneeling before the leader of the enemy side. She is pronouncing judgement on us and is making us wear crowns which symbolize our roles in the battle. I try to take off my crown because I hadn't wanted the violence, but she puts it back on. I think I convince her to let the other people go but I'm still going to be executed and am powerless to do anything about it.",
    notes: ""
  },
  {
    title: "Consequence",
    date: "2023-01-27 [age 28]",
    content: "I'm a woman in some kind of facility with someone like my boss, but I can't be sure if it was him. I was sort of trapped. He let me know that there wasn't any consequence for rape unless the victim signed a form. I said that I would sign it but he said that it wouldn't matter as he could easily claim to have been going through a difficult time. He might say for example that he really wanted to be a doctor and he finally had become one and now was different. He moved toward me and I got up and said that I was leaving.",
    notes: ""
  },
  {
    title: "Dorothy & The Mad Hatter",
    date: "2023-04-14",
    content: "I'm sitting on the couch in my parents house and my mom approaches. She's talking to me and acting overly friendly and obsequiously. She puts her hands on the pillow resting in my lap as I sit with my knees pulled up. \n" +
        "\n" +
        "I tell her that she is too close to me and that she should take a step back. She is affronted and talks about how she is a woman and once had a vagina and asks what kind of relationship we have. She moves to the other side of the couch and asks which character she is and lists a few characters from Alice in Wonderland. She kind of asks at the end if she is the Mad Hatter. She asks who I am and I say that I am Dorothy. She is confused and I realize that Dorothy is from The Wizard of Oz. ",
    notes: ""
  },
  {
    title: "Stolen Memories",
    date: "2023-04-14",
    content: "Part of a mission, sort of in a video game, where we were preparing and searching for weapons. Everyone else was getting the guns before I was. I had a pistol but was looking for something better. I also had a plastic toy pistol which I wasn't sure what it was for. We walked into rooms with tables and rummaged around to find weapons and bullets. I found two kinds of bullets and took some of each not knowing what my gun needed. I also found two knives and kept the second one as it looked more dangerous. \n" +
        "\n" +
        "After this I was getting ready and was near the woman who was leading the mission. She was an imposing figure. When she saw that I was holding the broken toy plastic gun she got a funny look in her eyes. I asked her why she had that look and said that the gun must have been hers. She denied it but I pressed her on it. Eventually she told me that I had stolen her memories. I had done the procedure incorrectly and so I didn't remember either. She brought in a man who was going to help her to get them back. He was some sort of doctor and was going to perform surgery on me to temporarily remove my brain. I made to run but realized that they would stop me. I decided to go along with the procedure and spoke to the doctor about whether they really had to remove my brain.",
    notes: ""
  },
  {
    title: "Janeway & The Ferengi",
    date: "2023-04-27",
    content: <>
      I was Captain <span style={{fontWeight: 500}}>Kat</span>herine Janeway from Star Trek Voyager and there was a Ferengi who loved me and wanted to make love to me. We were in a dark room and he was lying on top of me. I said something about discovering how brutal their sexual practices normally were but we were going to do something different to make it work. I caressed his ears and I could feel his genitalia press into mine. It was very romantic.
    </>,
    notes: "There's a reason I went with the name Kat!"
  },
  {
    title: "In the Mirror",
    date: "2023-05-19",
    content:
        "I stepped inside of a bathroom where a woman, one of my friends, was showing me that the shirt that I was wearing was too short and looked more like a dress. Sure enough I looked down at my chest and saw that the blue shirt dotted with white spots or symbols looked like a dress. Curious to see what I looked like in a dress I looked in the mirror and saw a woman looking back. It was me, but with a much more feminine face, my hair done up nicely, and wearing red lipstick. Somehow I recognized it as just me though upon waking it wasn't exactly my face. I thought I looked quite attractive.",
    notes: ""
  },
  {
    title: "Abortion",
    date: "2023-06-09",
    content: "I'm a woman in a creepy garage with a guy. I get impregnated but decide to get an abortion. I feel empowered but this isn't something that would normally align with my values. I can even feel the child inside me but have the mindset of \"fuck that\".\n" +
        "\n" +
        "I have to go get an abortion kit which I can then take to a doctor. I walk and ride the bicycle which represents the kit in front of everyone at some kind of college party. I don't want them to see who I am. I'm followed by a girl through the dorm halls. We're in the 200s when need to be at the 1500s. I'm lost and then we get out to an open room with lots of desks set up. It's a study hall I am somewhat familiar with. I'm a mam again for a while and I reassure the woman that everything is fine and that we're awake even though we're dreaming.\n" +
        "\n" +
        "Then it's just me lying in a bed and wondering if I impregnated myself in the moment I changed genders.",
    notes: ""
  },
  {
    title: "Wedding Dance",
    date: "2023-10-12",
    content: "I'm at a wedding where I have to dance with the father of the bride, but he may also just be my father. I can't dance and try to tell him that but I end up tripping him and we fall. He tries to lead me but I'm all out of sync. \n" +
        "\n" +
        "He takes me to the mother who laughs not meanly but still not making me feel good. She shows me how to dance, opposed but opposite and congruent movements. Spinning almost hectically back and forth. I realize that this could be fun. Her dress looks very sparkly.",
    notes: ""
  },
  {
    title: "Window or Mirror?",
    date: "2024-01-16 [age 29]",
    content: "I'm in a bedroom or bathroom sitting in front of what I take in the dream to be a window. I'm looking at a friend [non-binary but often female presenting] on the other side who says that when they look into a mirror they don't see themselves as attractive. I tell them that I could say how I see them but that it probably wouldn't help. They say they wants to hear anyway. I say that they're very pretty but they want to know why specifically. As I look at their face and try to come up with an answer, I realize that all of the individual features of their face are quite plain. What I find attractive is how they all come together into the person and personality that they are. I am having trouble formulating this in a way that sounds right, so I ask them for a minute to compose my thoughts. I think that I start to answer.\n" +
        "\n" +
        "Upon waking up from the dream, I realize that I had not been looking through a window but into a mirror.",
    notes: ""
  },
  {
    title: "Novels",
    date: "2024-01-24",
    content: "I'm speaking with a college student about a book he's writing. I think his name is something like \"Isley\". It's a trash novel but I assume there are people who must enjoy it. He shows me the second one which isn't yet published. It has his name and then \"With Jesus as King\". It also has some kind of an Amazon label. The main character is the author which seems egotistical but I accept it. I start reading and it is very choppy with short paragraphs, and I quickly become part of the story. I'm a woman on an airplane. I look out the escape hatch which another woman says is the escape pod door. I correct her and explain that there aren't any escape pods on an airplane. I see through the window the faces of scientists in a dark room like a movie theater. They're holding clipboards and pens and are taking notes. I realize we're not really in a plane but part of an experiment. I open the escape hatch and jump out. I fall and fall through grey clouds and look up to see the sky with just a faint glimmer of light. Looking down it's only grey. I feel very calm. I get a glimpse of a landscape with rolling green hills and I'm very thankful to be back.",
    notes: ""
  },
  {
    title: "Redemption",
    date: "2024-02-01",
    content: "I'm stealing and flying a small fighter plane, hoping to be able to claim that it was a mistake and thought it was a car. I'm pretty sure I'm a woman as I get caught. I am locked up but maybe escape again and at some point disabled the GPS tracker. I hear that a terrorist has commandeered a larger plane and is taking it to bomb something eight hours away. This gives me an opportunity to redeem myself and I fly after planning to fire missiles at the other plane. I don't know if I do or if the other plane pulls away, maybe escaping to an island off the west coast. All in the UK.\n",
    notes: ""
  },
  {
    title: "Spiritual Union",
    date: "2024-02-25",
    content: "I'm in the dark listening to a debate with Destiny. I'm on the call and there are several other guys talking with him. Then I'm at his house, where Melina is sitting on the couch with two guys who I don't know/remember. I'm sitting in front of the couch looking at them. She's very pretty with dark hair, as opposed to her actual hair color of blond. \n" +
        "\n" +
        "She says something about how she's too young for me. I say that I don't see why this would be a problem, I think because nothing romantic had been stated between us, and because I was 27 and I thought she was around 23. She acts like this is somehow offensive or shocking, and I ask her what she means. I'm pretty sure she's aware of the romantic implications of her first statement, and I want her to clarify - this is all pretty vague and hard to remember clearly. \n" +
        "\n" +
        "She pretends to not know what I'm talking about, but then says something about being tickled when she was a kid. We then go outside together. I tell her that I'm 27 and that she can trust me to not show any romantic interest and that we can just be friends. I then ask her if she wants to jump together. We both jump and can easily get 10 to 15 feet into the air, soaring and landing gently back down. There's something already special about this, but then we hold onto one another and jump together, and we soar straight up into the sky and are looking down at the landscape below us. We are both a bit scared about what will happen on the way down. We fall holding onto one another, and she's above me, with me holding onto her feet. The surrounding sky has become black, like outer space, and she has become a silvery spiritual outline of a naked female body. I pull her toward me and start having oral sex with her. At the end I reach for myself as well, and I pull myself toward her, through her vagina head first, and up through the inside of her abdomen and torso and all the way out through her mouth such that we merge entirely and I no longer feel any distinction between myself and her.\n",
    notes: ""
  },
  {
    title: "Horseback Riding",
    date: "2024-03-12",
    content: "I was riding a horse with a large, muscular man, almost enormous, with long black dreadlocks. We were riding down a dirt road getting into a town or village. We stopped and the man and I rolled off to the side of the road where I think he wanted to have sex. I, in this dream, was a woman, but as we lay there, on top of one another, I don't know whether I was the man or woman. I know that the woman was at first surprised at the man's intentions but wanted to go along with it. We started to and it was nice, but also a little exposed as we had no privacy. ",
    notes: ""
  },
  {
    title: "B'Elanna Torres",
    date: "2024-04-06",
    content: "I find myself on Voyager, look up and the crew is gone. There's a line. I was distracted. What do I do? The same thing I had been. Music? I was B'Elanna Torres.",
    notes: ""
  },
  {
    title: "Racecar Driver",
    date: "2024-04-29",
    content: "I'm at a kind of amusement park place. I'm in the passenger seat of a racecar with a woman driving. On the first round, we place well, but not first place. I wonder how she will do better the second time if her car simply is slower than the other cars. One of the others was a silver Porsche though ours had more the vibe of an F1 racecar, something with an open front anyway.\n" +
        "\n" +
        "On the second round, we're doing well but get stuck and have to stop for traffic, almost sliding off of the road into the water as the road/track is all above the ocean. I'm able to engage and quickly charge some kind of booster feature of the car which kicks in and launches us back up with so much force that we sail over the other cars and go straight into the finish line. There were a handful of finish lines that one could enter (possibly one for each car) and they went into a great domed building with an arena inside that reminded me of a gym's basketball court. I was surprised that we made it in first place having expected other cars to be further along. Maybe they also got stuck in traffic.\n" +
        "\n" +
        "When we drive inside, I become the woman. Once I'm out of the car, I see that it is fairly dim inside with little sunlight, only pouring through the open entrances to the left. There's a plate which slides out from the wall and is large enough for a car to rest on. I climb up onto it, maybe wondering if this will be difficult as I'm now a woman with less upper body strength. I have no difficulty and the wall begins to recede bringing me back outdoors to the award ceremony. I have to jump quickly to avoid falling but I believe that I managed it!",
    notes: ""
  },
  {
    title: "Mistaken Identity",
    date: "2024-07-01",
    content: "I was out and about and someone came up to me and mistook me for a woman. I corrected them but only halfheartedly and didn't really mind.",
    notes: ""
  }
]

export default function GirlDreams() {
  return (
    <div className="App">
      <h1>lilykat</h1>
      <i>dreams of being a girl</i>
      <hr className="separator" />
      <small>
        <a href={"#girl-dreams"}>goto: dreams</a>
      </small>
      <p>Hi! I'm Kat, and I've been keeping a dream journal for the past 8 years, and it's really that to which I attribute the most significance in my understanding of my gender identity. Ever since I was a kid, I remember both dreams of being a girl, and dreams of having a special connection to the feminine. Those were always my favorite dreams! Sadly, because I grew up in a very conservative and Christian family, there wasn't any safe way to express those dreams (or femininity generally) in my waking life, so all of that got repressed for many years.</p>

      <p>
        Once I started keeping a dream journal consistently after university, I began to recognize that pattern in my dreams. Most often these dreams involved a mysterious woman or feminine figure who I sensed I had a special connection to. In some of the dreams, I simply was that woman. I think it was those experiences which really motivated me to keep recording my dreams. At first these dreams were shrouded by mystery and romantic interest, but at some point that veil lifted and I saw that this "girl of my dreams" was a real person within me and that I really wanted to get to know her. Over the years I did that and she was discovered to be a fairly developed person with her own feelings, perceptions, desires, and need to be expressed in the world. One morning when I was just waking up and still half in dreams, she asked me, "Why do you leave me behind every morning when you get out of bed?"
      </p>

      <p>
        I didn't have an answer. I knew that I wanted to express myself more femininely, and to bring that woman to life in some way, but because of my upbringing and internalized transphobia it never occurred to me that I could simply <i>be a girl.</i> Prior to coming out as trans, I thought of myself as "a man who didn't feel like a man" but I didn't recognize that this was significant. I thought that this was normal and assumed that every guy wanted, if secretly, to be a girl. I even knew non-binary and trans people and had felt that there was some connection there but had never considered that I might one day find myself among them.
      </p>
      <p>
        But that all changed when I made the mistake of getting on TikTok. The conservatives had told me that TikTok was out to queer the kids, but I didn't realize that it could happen to adults too! I only got on TikTok to connect with musicians in my area, but since I lived in Portland OR, it turned out that a lot of those musicians happened to be trans. The algorithm apparently made the assumption that if I enjoyed content from trans musicians that I would enjoy content from trans creators generally. That assumption was correct. Before I knew it, I was watching dozens of videos about transitioning, timelines, effects of HRT, women's fashion, voice training, etc. Because all of this content was so relatable, I was able to consider for the first time that I might be non-binary or trans and that transitioning was actually something that I could do. It was no longer some mysterious and taboo thing that "other" people did.
      </p>
      <p>
        ...
      </p>
      <p>
        Fast-forward six months, and several gender crises, and I've now been on HRT for two months. I went through a month or two of identifying as non-binary and using they/them pronouns, but I very quickly decided that I was trans. I changed my name to Kat (she/they), came out to family and friends, and took the steps to start HRT. Looking back, I realize that this all happened very quickly, but overall the transition has been very smooth and overwhelmingly positive. I think that I again attribute this mostly to my dreams. Even though I wasn't thinking about gender very much consciously, I clearly had been processing a lot of it in my dreams where all of the things unconscious and repressed came out to play. Now that I'm in the process of transitioning, I've been spending more time reflecting on those dreams and trying to understand how exactly it is that I've gotten to where I am now.
      </p>
      <p>
        I went through my dream journal and took note of all of the dreams where I was a girl or where gender identity played a significant role and organized them into a little collection so that I could review them further. I learned a lot from doing that, and though sometimes difficult because of the things it brought up, it was wonderfully very affirming. There was a lot more about gender identity in my dreams than I had thought and I left with the impression that I was a little bit silly for not having figured any of this out sooner! How many years could I have saved if I had been paying more attention to my dreams earlier on? To what extent can dreams tell us about our own identities generally?
      </p>
      <p>
        Even as someone who studies dreams for a living (and helps to make a super cool dream journaling app called <a href={"https://www.elsewhere.to/"}>Elsewhere</a> which you should totally check out by the way) I don't have the answer to those questions. No one has ever explored the intersection between dreaming and gender identity beyond characterizing the dreaming patterns of those who identify with the binary male or female. I'm hoping to focus on these questions in the future, but I thought that I could already do something for both research and the gender diverse community by publishing this along with a collection of my dreams about gender identity. My hope is that this will spark some curiosity about dreaming and gender and that maybe someone who stumbles across this will find it relatable and helpful on their own journey.
      </p>

      <p>
        Anyway, here are all of those dreams! I've removed names and other personally identifying information and in some cases have made minor edits to dreams that I felt would otherwise be too graphic or intense. I've also added some of my own notes and interpretations where I thought that would be helpful.
      </p>

      <p>
        <hr className="separator" />
        <b>content warning:</b> some dreams contain descriptions of sex, physical abuse, violence, etc.
        <hr className="separator" />
      </p>

      <h2 id={"girl-dreams"}>Pre-Transition Dreams</h2>
      {preTransitionDreams.map((dream, i) => (
        <div key={i} className={"dream"}>
          <h3>{dream.title}</h3>
          <p className={"date"}>{dream.date}</p>
          <p>{nl2br(dream.content)}</p>
          {dream.notes !== '' && (<p>
            <p>...</p>
            <small>
              <i>{dream.notes}</i>
            </small>
          </p>)}
          <hr className="separator" />
        </div>
      ))}

      <h2>Transition Dreams</h2>
      <i>Coming soon...</i>
      <br />
      <br />
    </div>
  )
}