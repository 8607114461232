import React from "react";
import nl2br from "react-nl2br";

const songs = [
  {
    title: "Anthony's Dream",
    audio: "/audio/anthonys-dream.mp3",
    lyrics: `
Where are, where are the stars?

Lost in a world with no northern star

Shifting landscape a broken car

Where are, where are the stars?

Where are, where are...

...the stars have set sail for the distant sun

The journey is long and the night is young
`
  }
];

export default function Music() {
  return (
      <div className="App">
        <h1>lilykat</h1>
        <i>writes songs</i>
        <hr className="separator" />
        <small>
          <a href={"#songs"}>goto: songs</a>
        </small>
        <p>
          Hi! I'm Kat, and I write music! Until very recently I've almost exclusively done this on my own in my little bedroom studio, but recently I've been working with some really cool musicians and have my own Discord server where trans and other gender diverse people hang out and collaborate on things.
        </p>

        <p>
          You can <a href={"https://discord.gg/PnNYdatsUB"}>join it here</a>!
        </p>

        <p>Below are a couple of the latest songs I/we have been working on.</p>

        <hr className="separator" />

        <h2 id={"songs"}>Songs</h2>
        {songs.map((song, i) => (
            <div key={i} className={"dream"}>
              <h3>{song.title}</h3>
              <audio controls src={song.audio} />
              <p>
                <i>{nl2br(song.lyrics)}</i>
              </p>
              <hr className="separator" />
            </div>
        ))}
      </div>
  )
}