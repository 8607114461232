import React from 'react';
import {
    Routes, Route, Link, BrowserRouter as Router
} from "react-router-dom";
import './App.css';
import Home from "./screens/Home";
import TransFem from "./screens/TransFem";
import GirlDreams from "./screens/GirlDreams";
import Music from "./screens/Music";
import NotFound from "./screens/NotFound";
import BlogPostView from "./screens/BlogPostView";

function App() {
  return (
      <Router>
          <div>
              <nav>
                  <ul>
                      <li>
                        <Link to="/">home</Link>
                      </li>
                      <li>
                        <Link to={"/music"}>music</Link>
                      </li>
                      <li>
                          <Link to={"/girl-dreams"}>girl dreams</Link>
                      </li>
                      <li>
                        <Link to="/transfem">transfem</Link>
                      </li>
                    <li>
                      <Link to="https://discord.gg/PnNYdatsUB">join the discord</Link>
                    </li>
                  </ul>
              </nav>
              <Routes>
                  <Route path="/transfem" element={<TransFem />} />
                  <Route path="/girl-dreams" element={<GirlDreams />} />
                  <Route path="/music" element={<Music />} />
                  <Route path="/post/:id" element={<BlogPostView />} />
                  <Route path="/" element={<Home />} />
                  <Route path="*" element={<NotFound />} />
              </Routes>
          </div>
      </Router>
  );
}

export default App;
